@import "../libs/mixin";
@import "../libs/variables";

#menu-new {
  background: $menuColor;
  color: $menuFontColor;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 9;
  header {
    @include border-box;
    padding: 15px 5px;
    background: $menuBorderColor;
    text-align: center;
    img {
      max-width: 75%;
      max-height: 75%; } }
  #menu {
    a.a {
      background-color: #F77C16; }
    .main-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
      @include no-select;
      li {
        -webkit-box-shadow: inset 4px 0 0 0 $menuColor;
        -moz-box-shadow: inset 4px 0 0 0 $menuColor;
        box-shadow: inset 4px 0 0 0 $menuColor;
        @include transform;
        label {
          display: block;
          padding: 10px;
          cursor: pointer;
          border-bottom: 1px solid $menuBorderColor;
          font-weight: normal;
          font-size: 12px;
          position: relative;
          margin: 0;
          @include transform;
          i {
            padding-right: 20px; }
          &:after {
            width: 10px;
            height: 6px;
            content: '';
            background: url("/gfx/refactor/arrow_down.png");
            position: absolute;
            right: 15px;
            top: 15px;
            @include transform; }
          &:hover {
            color: $menuFontHoverColor; } }
        ul {
          display: none;
          margin: 0;
          padding: 0;
          background: $menuBorderColor;
          color: $menuFontColor;
          list-style: none;
          li {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            a {
              display: block;
              padding: 10px 10px 10px 20px;
              font-size: 12px;
              color: $menuFontColor;
              border-bottom: 1px solid $menuColor;
              text-decoration: none;
              @include transform;
              &:hover {
                color: $menuFontHoverColor; }
              &.a {
                -webkit-box-shadow: inset 4px 0 0 0 $menuFontHoverColor;
                -moz-box-shadow: inset 4px 0 0 0 $menuFontHoverColor;
                box-shadow: inset 4px 0 0 0 $menuFontHoverColor;
                color: $menuFontHoverColor; } } } }
        &.a {
          -webkit-box-shadow: inset 4px 0 0 0 $orangeColor;
          -moz-box-shadow: inset 4px 0 0 0 $orangeColor;
          box-shadow: inset 4px 0 0 0 $orangeColor;
          label {
            color: $menuFontHoverColor;
            i {
              color: $orangeColor; }
            &:after {
              -webkit-transform: rotate(-180deg);
              -moz-transform: rotate(-180deg);
              -ms-transform: rotate(-180deg);
              -o-transform: rotate(-180deg);
              transform: rotate(-180deg); } } } } } } }
