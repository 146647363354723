@mixin transform {
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

@mixin border-box{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin no-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin border-radius($border) {
  -webkit-border-radius: $border;
  -moz-border-radius: $border;
  border-radius: $border;
}

@mixin boxShadow($top, $left, $blur, $spread, $color, $inset: false) {

  @if ($inset) {
    -webkit-box-shadow: inset $top $left $blur $spread $color;
    -moz-box-shadow: inset $top $left $blur $spread $color;
    box-shadow: inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }

}

@mixin noBoxShadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}