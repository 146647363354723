@import "libs/mixin";
@import "libs/variables";

#collapseTwo {
  &.set-rights {
    table {

      tr {
        border-bottom: 1px solid;
        &:last-of-type, &.header {
          border-bottom: none; }
        td {
          padding: 5px 3px;
          word-break: break-all;
          border-right: 1px solid;
          &:last-of-type {
            border-right: none; } }
        .chk {
          input {
            width: auto !important;
            margin: 0 5px; }
          label {
            margin: 0 5px 0 0 !important; } }
        &:not(.header) {
          &:nth-child(odd) {
            background: #eee; } }
        &.true {
          background: #dff0d8 !important;
          border-color: #d6e9c6 !important;
          td {
            border-color: #d6e9c6 !important; }
          &:hover {
            background: #cfe0c8 !important; } }
        &.false {
          background: #f2dede !important;
          border-color: #ebccd1 !important;
          td {
            border-color: #ebccd1 !important; }
          &:hover {
            background: #e1cdcd !important; } }
        &.default {
          background: #fcf8e3 !important;
          border-color: #faebcc !important;
          td {
            border-color: #faebcc !important; }
          &:hover {
            background: #e1ddc8 !important; } } } } } }
