@import "libs/mixin";
@import "libs/variables";

.wrapper {
  .btn-return {
    margin-bottom: 10px;
    i {
      margin-right: 5px; } }
  .col-xs-5 {
    margin-bottom: 50px;
    .panel {
      @include border-radius(0);
      .panel-heading {
        background: #eee;
        a {
          display: block;
          font-size: 12px; } }
      .panel-body {
        p {
          margin: 0 0 0 2px; } } } } }
