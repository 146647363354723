@import "../libs/mixin";
@import "../libs/variables";
.newGridBody {
  form {
    //padding: 10px;
    fieldset {
      padding-top: 30px;
      overflow: auto;
      width: 100%;
      .row {
        margin: 0;
        max-width: 100%; } } }
  .col-action {
    margin-top: 10px;
    .btn-group {
      margin-left: 15px;
      ul li i {
        margin-right: 10px; } } }
  .pager {
    .pageSels {
      .chosen-container {
        width: 130px !important;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px; } }
    .pager-new {
      margin-bottom: 10px;
      .item {
        display: inline-block;
        vertical-align: middle;
        margin: 10px 10px 0 0;
        &.pull-right {
          margin: 10px 0;
          .button-cogs {
            font-size: 13px;
            margin: 0 0 0 5px; }
          .dropdown-menu {
            left: auto;
            right: 0;
            padding: 10px;
            ul {
              margin: 0;
              padding: 0;
              li {
                display: block;
                margin: 3px 0;
                input {
                  margin: -2px 5px 0 0; }
                label {
                  color: $menuColor;
                  cursor: pointer; } } } } }
        .pagination {
          margin: 0;
          li {
            a {
              border-radius: 0; }
            span {
              border-radius: 0; } } }
        label {
          color: $greyColor;
          font-weight: normal;
          display: inline-block;
          vertical-align: middle; }
        .chosen-container {
          width: 60px !important;
          margin-left: 10px; } } } } }
