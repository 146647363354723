@import "../libs/mixin";
@import "../libs/variables";

.form-control {
  &::-ms-browse {
    border-left: 1px solid #ccc !important;
    cursor: pointer; }
  &::-ms-value, &::-ms-browse {
    background-color: transparent;
    outline: none;
    border: none; } }
#zip {
  padding: 0; }

form {
  &.form {
    float: none !important;
    width: 100% !important;
    fieldset {
      background: transparent !important;
      label {
        float: none !important;
        width: auto !important;
        padding: 0 !important;
        &.error {
          color: #dd0000;
          font-weight: normal; } }
      .checkbox {
        margin-left: 20px; } }
    &.validate {
      .form-group {
        .form-control {
          &.error {
            border-color: #a94442;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            &:focus {
              border-color: #843534;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; } } } } } } }
