@import "../libs/mixin";
@import "../libs/variables";

#login-form {
  .row {
    form {
      background: #fff;
      width: 350px !important;
      padding: 30px 20px;
      margin: 40px auto 0;
      border: 1px solid rgba($menuColor, 0.1);
      @include border-radius(4px);
      .company-logo {
        margin-bottom: 30px; } } } }
