@import "../libs/mixin";
@import "../libs/variables";

.miniModal {
  margin-top: 20px;
  select.sug_sel {
    width: 100%;
    left: -10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    margin: 30px 0 0 10px; } }
