.fit {
  width: 100% !important;
}

.tr-border {
  box-sizing: border-box;
  border: 3px solid transparent;
}

.flex-form-container, .flex-form-group-half {
  width: 16.66667%;
  max-width: 16.66667%;
  flex-grow: 1;
}

.flex-grow {
  max-width: none !important;
  flex-grow: 1;
  width: 100%;
}

.flex-form {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-form input::-webkit-input-placeholder {
  font-size: 12px;
}

.flex-form-container .flex-form-group-half {
  border: none;
  width: 100% !important;
}

.flex-form .chosen-container {
  width: 100% !important;
}

.flex-form .chosen-container input {
  font-size: 12px;
}

.flex-form-field {
  width: 100% !important;
}

.flex-form-btn-group {
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: content-box;
}

.flex-form-btn {
  margin: 0 5px;
}

.flex-form-btn .btn {
  margin: 0 !important;
}

.flex-form .search-field input {
  padding: 0 12px !important;
  color: #999999 !important;
}

@media only screen and (max-width: 1024px) {
  .flex-form-container {
    min-width: 25% !important;
  }
  .flex-form-group-half {
    min-width: 25% !important;
  }
  .flex-form-btn-group {
    padding-left: 0;
  }
  .flex-form-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .flex-form-container {
    min-width: 50% !important;
  }
  .flex-form-group-half {
    min-width: 50% !important;
  }
  .flex-form-btn-group {
    padding-left: 0;
  }
  .flex-form-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .flex-form-container {
    min-width: 100% !important;
  }
  .flex-form-group-half {
    min-width: 100% !important;
  }
  .flex-form-btn-group {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .flex-form-btn {
    margin: 2px 0;
  }
}

#menu-new {
  display: block;
  left: -100% !important;
  transition: .3s left ease-in-out;
  overflow: hidden !important;
}

#menu-new.open {
  left: 0 !important;
  transition: .5s left ease-in-out;
}

#menu-new.open #mobile-menu-toggle span:nth-child(1) {
  transform-origin: 50% 0%;
  transform: rotate(45deg) translate(8px, 7px);
}

#menu-new.open #mobile-menu-toggle span:nth-child(2) {
  border-color: #F77C16;
  transform-origin: 50% 50%;
  transform: scale(0, 1);
}

#menu-new.open #mobile-menu-toggle span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -8px);
}

#mobile-menu-toggle {
  position: fixed;
  top: 0;
  left: 0;
  background: #2E373E;
  z-index: 1080;
  border-radius: 0 50% 50% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: .1s all ease-in;
  box-shadow: 1px 2px 2px rgba(2, 2, 2, 0.4);
}

#mobile-menu-toggle:hover {
  box-sizing: border-box;
  border: 2px solid #F8F9FB;
}

#mobile-menu-toggle span {
  border: 2px solid #F8F9FB;
  border-radius: 2px;
  width: 30px;
  margin: 3px 0 3px -3px;
  transition: .3s all ease-in-out;
}

#mobile-menu-toggle span:nth-child(2) {
  border-color: #F77C16;
}

@media only screen and (min-width: 720px) {
  #mobile-menu-toggle {
    display: none;
  }
  #menu-new, #menu-new.open {
    left: 0 !important;
  }
}

@media only screen and (max-width: 720px) {
  .new-body {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .new-body #menu-new {
    width: 100%;
  }
  .new-body #content {
    width: 100%;
    margin: 0;
  }
}

.flex-layout .sort-btns .sort-btn {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 5px 0 5px;
  border-radius: 2px;
}

.flex-layout .sort-btns .sort-btn a {
  color: black;
  text-decoration: none;
}

.flex-layout .sort-btns .sort-btn:hover {
  background-color: #eeeeee;
}

.flex-layout .sort-btns .sort-btn:hover .fa, .flex-layout .sort-btns .sort-btn:hover a, .flex-layout .sort-btns .sort-btn:hover label {
  color: deepskyblue;
}

.flex-layout .sort-btns .sort-btn .fa {
  font-size: 14px;
}

.flex-layout .sort-btns .sort-btn label {
  margin: 0 0 0 5px;
}

.flex-layout .sort-btns .sort-btn .end {
  color: #c0c0c0;
}

.flex-layout .sort-btns .sort-btn .not-allowed {
  cursor: not-allowed;
  color: #e6b8b8;
}

.sug .head strong {
  margin: 0 !important;
}

.sug .chk2 {
  margin: 0;
  padding: 2px 0 2px 10px;
  font-size: 12px;
}

.sug .chk2 input {
  margin: 0 5px 0 0;
}

.sug .chk2 label {
  margin: 0;
}

.scrollWrap {
  overflow: auto;
}

.scrollWrap-pd {
  padding: 10px 0;
}

.scrollWrap-brd {
  border: 2px solid #c0c0c0;
  border-right: none;
  border-left: none;
}

.flexTabs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.flexTabs span {
  height: 30px;
  user-select: none;
  width: 14.28571%;
  cursor: pointer;
  min-width: 100px;
  padding: 2px 5px;
  margin: 2px 2px 0 2px;
  flex-grow: 1;
  color: black;
  background-color: #EEEEEE;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.flexTabs span:hover {
  background-color: #ff9900;
}

.flexTabs span.a {
  transform: translate(0, 1px);
  margin: 2px 2px 0 0;
  border: 1px solid #888;
  border-bottom: none;
  background-color: #FFFFFF;
  color: black;
  font-weight: bold;
  order: 1;
}

#menu-new {
  background: #2e373e;
  color: #bebebe;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 9;
}

#menu-new header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 5px;
  background: #2c3339;
  text-align: center;
}

#menu-new header img {
  max-width: 75%;
  max-height: 75%;
}

#menu-new #menu a.a {
  background-color: #F77C16;
}

#menu-new #menu .main-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#menu-new #menu .main-menu li {
  -webkit-box-shadow: inset 4px 0 0 0 #2e373e;
  -moz-box-shadow: inset 4px 0 0 0 #2e373e;
  box-shadow: inset 4px 0 0 0 #2e373e;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

#menu-new #menu .main-menu li label {
  display: block;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #2c3339;
  font-weight: normal;
  font-size: 12px;
  position: relative;
  margin: 0;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

#menu-new #menu .main-menu li label i {
  padding-right: 20px;
}

#menu-new #menu .main-menu li label:after {
  width: 10px;
  height: 6px;
  content: '';
  background: url("/gfx/refactor/arrow_down.png");
  position: absolute;
  right: 15px;
  top: 15px;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

#menu-new #menu .main-menu li label:hover {
  color: #fff;
}

#menu-new #menu .main-menu li ul {
  display: none;
  margin: 0;
  padding: 0;
  background: #2c3339;
  color: #bebebe;
  list-style: none;
}

#menu-new #menu .main-menu li ul li {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#menu-new #menu .main-menu li ul li a {
  display: block;
  padding: 10px 10px 10px 20px;
  font-size: 12px;
  color: #bebebe;
  border-bottom: 1px solid #2e373e;
  text-decoration: none;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

#menu-new #menu .main-menu li ul li a:hover {
  color: #fff;
}

#menu-new #menu .main-menu li ul li a.a {
  -webkit-box-shadow: inset 4px 0 0 0 #fff;
  -moz-box-shadow: inset 4px 0 0 0 #fff;
  box-shadow: inset 4px 0 0 0 #fff;
  color: #fff;
}

#menu-new #menu .main-menu li.a {
  -webkit-box-shadow: inset 4px 0 0 0 #f77c16;
  -moz-box-shadow: inset 4px 0 0 0 #f77c16;
  box-shadow: inset 4px 0 0 0 #f77c16;
}

#menu-new #menu .main-menu li.a label {
  color: #fff;
}

#menu-new #menu .main-menu li.a label i {
  color: #f77c16;
}

#menu-new #menu .main-menu li.a label:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

strong.head {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

strong.head + div {
  padding: 5px;
  border: 2px solid #eeeeee;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

#content .bGridSearch .col-xs-10, .content .bGridSearch .col-xs-10 {
  width: calc(100% - 231px - (2*15px));
}

#content .bGridSearch .col-xs-2, .content .bGridSearch .col-xs-2 {
  width: 231px;
}

#content .bGridSearch .col-xs-2 .form-group, .content .bGridSearch .col-xs-2 .form-group {
  margin: 10px 5px;
}

#content .bGridSearch .col-xs-2 .form-group .btn, .content .bGridSearch .col-xs-2 .form-group .btn {
  margin: 0;
}

#content .bGridSearch fieldset, .content .bGridSearch fieldset {
  background: transparent;
  text-align: left;
}

#content .bGridSearch fieldset .form-group, .content .bGridSearch fieldset .form-group {
  margin: 10px;
}

#content .bGridSearch fieldset .form-group:first-of-type, .content .bGridSearch fieldset .form-group:first-of-type {
  margin: 0;
}

#content .bGridSearch fieldset .form-group:nth-child(6n+0), .content .bGridSearch fieldset .form-group:nth-child(6n+0) {
  margin: 0;
}

#content .bGridSearch fieldset .form-group .input-group-addon, .content .bGridSearch fieldset .form-group .input-group-addon {
  font-size: 12px;
}

#content .bGridSearch fieldset .form-group .form-control, .content .bGridSearch fieldset .form-group .form-control {
  font-size: 12px;
}

#content .bGridSearch fieldset .form-group .chosen-container-single .chosen-single, .content .bGridSearch fieldset .form-group .chosen-container-single .chosen-single {
  font-size: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#content .bGridSearch fieldset .form-group .chosen-choices, .content .bGridSearch fieldset .form-group .chosen-choices {
  font-size: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#content .bGridSearch fieldset .form-group .tab-content, .content .bGridSearch fieldset .form-group .tab-content {
  margin: 10px 0 0;
}

#content .bGridSearch fieldset .form-group b.error, .content .bGridSearch fieldset .form-group b.error {
  color: #dd0000;
  font-weight: normal;
}

#content .bGridSearch fieldset .form-group label.error, .content .bGridSearch fieldset .form-group label.error {
  color: #dd0000;
  font-weight: normal;
}

#content .bGridSearch fieldset .form-group .required, .content .bGridSearch fieldset .form-group .required {
  color: #dd0000;
}

#content.forceLanguageFix {
  margin-top: 50px;
}

#header-new .first-line {
  background: #fff;
  border-bottom: 1px solid rgba(46, 55, 62, 0.1);
}

#header-new .first-line .col-xs-7 {
  text-align: right;
}

#header-new .first-line .col-xs-7 ul {
  right: 0;
  left: auto;
}

#header-new .first-line .col-xs-7 ul a {
  color: #8e8e8e;
  font-size: 10px;
}

#header-new .secount-line {
  padding: 5px 15px;
  background: #fff;
  border-bottom: 2px solid rgba(46, 55, 62, 0.1);
}

#header-new .secount-line #localizer-new {
  margin-top: 10px;
}

#header-new .secount-line #localizer-new .localizer-item {
  color: #8e8e8e;
  margin: 10px;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
  position: relative;
}

#header-new .secount-line #localizer-new .localizer-item:hover {
  color: #f77c16;
}

#header-new .secount-line #localizer-new .localizer-item:last-child {
  color: #f77c16;
}

#header-new .secount-line #localizer-new .localizer-item.no-arrow {
  margin: 0;
}

#header-new .secount-line #localizer-new .localizer-item:not(.no-arrow):not(:last-child):before {
  width: 10px;
  height: 10px;
  content: '';
  background: url("/gfx/refactor/arrow_down.png") no-repeat;
  position: absolute;
  right: -17px;
  top: 3px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

#header-new .secount-line #userDrop {
  background-color: #EAEBEC;
  display: inline-block;
  height: 100%;
  padding: 0 0 0 20px;
  border-radius: 20px;
  cursor: pointer;
}

#header-new .secount-line #userDrop .user-image {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
}

#header-new .secount-line #userDrop .user-image img {
  max-width: 100%;
  min-height: 100%;
  border-radius: 150px;
}

#header-new .secount-line #userDrop .user-name {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

#header-new .secount-line #userDrop .user-name p {
  color: #8e8e8e;
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -ms-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
  margin: 0;
}

#header-new .secount-line #userDrop .dropdown-menu {
  margin-top: 5px;
}

#header-new .secount-line #userDrop .dropdown-menu li i {
  margin-right: 10px;
}

#header-new .secount-line #userDrop .dropdown-menu li a {
  font-size: 12px;
}

.brd {
  border: 1px solid #aaa;
  clear: both;
  padding: 5px 10px;
  margin: 3px 0;
}

.win_brd {
  display: none;
  position: relative;
  margin: 5px 0;
  border: 1px solid #aaa;
}

.win_brd .close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 11px;
  height: 11px;
  background: url("/gfx/close.gif");
  z-index: 100;
  cursor: pointer;
}

#content hr, .content hr {
  margin: 5px 0;
}

#content table.tableData, .content table.tableData {
  clear: both;
  color: #444;
  width: 100%;
}

#content table.tableData thead img.srt_1, .content table.tableData thead img.srt_1 {
  position: relative;
  top: -4px;
  left: 4px;
  margin: 0;
}

#content table.tableData thead img.srt_2, .content table.tableData thead img.srt_2 {
  position: relative;
  top: 4px;
  left: -7px;
  margin: 0;
}

#content table.tableData thead th, #content table.tableData thead td, .content table.tableData thead th, .content table.tableData thead td {
  border: 1px solid #E3ECEE;
  padding: 5px;
  text-align: left;
  color: #333;
  white-space: nowrap;
}

#content table.tableData tbody th, #content table.tableData tbody td, .content table.tableData tbody th, .content table.tableData tbody td {
  padding: 4px 5px;
  text-align: left;
  border: 1px solid #D0DFE2;
}

#content table.tableData tbody td img, .content table.tableData tbody td img {
  margin: 0 2px;
}

#content table.tableData tbody td img.img, .content table.tableData tbody td img.img {
  margin: 0 2px;
}

#content table.tableData tbody tr:hover th, #content table.tableData tbody tr:hover td, .content table.tableData tbody tr:hover th, .content table.tableData tbody tr:hover td {
  background: #d2d2d0;
}

#content table.tableData tbody tr.t:hover th, #content table.tableData tbody tr.t:hover td, .content table.tableData tbody tr.t:hover th, .content table.tableData tbody tr.t:hover td {
  background: #d2d2d0;
}

#content table.tableData tbody tr.mx_up th, #content table.tableData tbody tr.mx_up td, .content table.tableData tbody tr.mx_up th, .content table.tableData tbody tr.mx_up td {
  border-top: 2px solid #444;
}

#content table.tableData tbody tr.mx_down th, #content table.tableData tbody tr.mx_down td, .content table.tableData tbody tr.mx_down th, .content table.tableData tbody tr.mx_down td {
  border-bottom: 2px solid #444;
}

#content table.mx_down, .content table.mx_down {
  border-bottom: 2px solid #444;
}

#content table.tableData tbody tr.mx_in th, #content table.tableData tbody tr.mx_in td, .content table.tableData tbody tr.mx_in th, .content table.tableData tbody tr.mx_in td {
  background: #263538;
  color: #fff;
}

#content table.tableData tbody tr.mx_sel th, #content table.tableData tbody tr.mx_sel td, .content table.tableData tbody tr.mx_sel th, .content table.tableData tbody tr.mx_sel td {
  background: #5C807C;
  color: #fff;
}

#content .btn-no-rights, .content .btn-no-rights {
  float: left;
  padding: 0 15px;
  border-bottom: 1px solid #A04300;
  border-right: 1px solid #A04300;
  border-left: 1px solid #ED6502;
  border-top: 1px solid #ED6502;
  background: #FFAC47 url("/gfx/nbtn_bg.gif") repeat-x 0 100%;
  color: #aaa;
  text-decoration: line-through;
  margin: 20px 5px;
  white-space: nowrap;
  vertical-align: middle;
  height: 19px;
  line-height: 19px;
}

#content .btn img, #content .btn-no-rights img, .content .btn img, .content .btn-no-rights img {
  position: relative;
  top: -1px;
  left: -6px;
}

#content input.btn, .content input.btn {
  margin: 0;
  width: auto;
}

#content .tableData .btn-no-rights img, .content .tableData .btn-no-rights img {
  position: relative;
  left: -6px;
  top: -1px;
}

#content .tabs .btn, #content .tabs .btn-no-rights, .content .tabs .btn, .content .tabs .btn-no-rights {
  font-size: 9px;
  /*/border: none; */
}

#content .ret, .content .ret {
  background: url("/gfx/btn_bg2.gif") repeat-x 0 100%;
  border: 1px solid #ccc;
  color: #666;
}

#content .ret img, .content .ret img {
  position: relative;
  top: -1px;
  left: -6px;
}

#content .form, .content .form {
  float: left;
  width: 99%;
  margin: 0;
  padding: 2px;
  position: relative;
  top: 25px;
  border: 1px solid #ccc;
  background: #fff;
}

#content .form table.list, .content .form table.list {
  clear: both;
  margin: 0 0 10px 60px;
}

#content .form table.list b.error, .content .form table.list b.error {
  margin: 0;
}

#content .form table.list th, .content .form table.list th {
  padding: 7px 3px 2px 3px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

#content .form table.list td, .content .form table.list td {
  padding: 2px 6px;
}

#content .form table.list label, .content .form table.list label {
  width: auto;
}

#content .form table.list input, .content .form table.list input {
  margin: 0 3px 0 0;
}

#content .form fieldset, .content .form fieldset {
  margin: 0;
  padding: 10px;
  border: none;
  background: transparent url("/gfx/form_bg.gif") repeat-x;
}

#content .form fieldset legend, .content .form fieldset legend {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  position: absolute;
  color: #666;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  left: 10px;
  top: -25px;
  background: #fff;
}

#content form.form select.multiple, .content form.form select.multiple {
  height: 100px;
  min-width: 200px;
}

#content form.form label, .content form.form label {
  float: left;
  width: 29%;
  padding: 1px 10px 0 0;
  text-align: right;
  vertical-align: middle;
}

#content form.form b.error, .content form.form b.error {
  display: block;
  clear: both;
  color: #f00;
  margin: 0 0 0 210px;
  font-size: 10px;
}

#content form.form div.chk label, .content form.form div.chk label {
  cursor: pointer;
}

#content form.form div.chk input, .content form.form div.chk input {
  cursor: pointer;
  border: none;
  width: auto;
}

#content form.form div.chk2 label, .content form.form div.chk2 label {
  width: auto;
  cursor: pointer;
  float: none;
}

#content form.form div.chk2 input, .content form.form div.chk2 input {
  cursor: pointer;
  border: none;
  width: auto;
}

#content form.form td.chk input, .content form.form td.chk input {
  cursor: pointer;
  padding: 0;
  border: none;
  width: auto;
}

#content form.form td.chk label, .content form.form td.chk label {
  display: inline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: auto;
  float: none;
}

#content div.brd, .content div.brd {
  border: 1px solid #aaa;
  clear: both;
  padding: 5px 10px;
  margin: 3px 0;
}

#content div.brd .btn, .content div.brd .btn {
  margin: 0;
}

#content div.win_brd, .content div.win_brd {
  display: none;
  background: #f8f8f8;
  position: relative;
  width: 100%;
  border: 1px solid #aaa;
  clear: both;
  padding: 5px 10px;
  margin: 10px 0;
}

#content div.msg_info, .content div.msg_info {
  border: 1px solid #ccc;
  font-weight: bold;
  padding: 10px 15px;
  text-align: left;
  background: #fff;
  margin: 0 10px;
}

#content p.msg, .content p.msg {
  border: 1px solid #8DAE86;
  font-weight: bold;
  padding: 10px;
  margin: 5px 0;
  text-align: center;
  background: #DDF3CB;
}

#content p.msg_error, .content p.msg_error {
  border: 1px solid #E08787;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  background: #FEEAE4;
  margin: 5px 0;
}

#content div.diver p.msg_error, #content div.diver p.msgr, .content div.diver p.msg_error, .content div.diver p.msgr {
  margin: 20px 0 5px 0;
}

#content span.required, .content span.required {
  color: #f00;
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 10px;
}

#content div#mask_window .msg_error, .content div#mask_window .msg_error {
  position: absolute;
  padding: 5px 10px;
  top: -43px;
  left: 200px;
  right: 200px;
  z-index: 1005;
}

#content div#mask_window form.form, .content div#mask_window form.form {
  margin: 0;
  top: 0;
}

#content div#mask_window form.form fieldset legend, .content div#mask_window form.form fieldset legend {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  position: absolute;
  color: #666;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  left: 10px;
  top: -35px;
  background: #fff;
}

#content div#mask_window span.required, .content div#mask_window span.required {
  right: 35px;
  top: -15px;
}

#content label span.required, #content strong span.required, .content label span.required, .content strong span.required {
  position: static;
}

#content form.form table.multi, .content form.form table.multi {
  margin: 0 0 0 200px;
}

#content form.noborders, .content form.noborders {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  position: static !important;
  background: transparent !important;
}

#content form.noborders fieldset, .content form.noborders fieldset {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  position: static !important;
  background: transparent !important;
}

#content table.tableData.newGrid thead span, .content table.tableData.newGrid thead span {
  padding-right: 30px;
}

#content table.tableData.newGrid thead img.srt_1, .content table.tableData.newGrid thead img.srt_1 {
  position: absolute;
  top: 4px;
  right: 10px;
  margin: 0;
  left: auto !important;
}

#content table.tableData.newGrid thead img.srt_2, .content table.tableData.newGrid thead img.srt_2 {
  position: absolute;
  top: 4px;
  right: 0;
  margin: 0;
  left: auto !important;
}

.form-control::-ms-browse {
  border-left: 1px solid #ccc !important;
  cursor: pointer;
}

.form-control::-ms-value, .form-control::-ms-browse {
  background-color: transparent;
  outline: none;
  border: none;
}

#zip {
  padding: 0;
}

form.form {
  float: none !important;
  width: 100% !important;
}

form.form fieldset {
  background: transparent !important;
}

form.form fieldset label {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
}

form.form fieldset label.error {
  color: #dd0000;
  font-weight: normal;
}

form.form fieldset .checkbox {
  margin-left: 20px;
}

form.form.validate .form-group .form-control.error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

form.form.validate .form-group .form-control.error:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.newGridBody form fieldset {
  padding-top: 30px;
  overflow: auto;
  width: 100%;
}

.newGridBody form fieldset .row {
  margin: 0;
  max-width: 100%;
}

.newGridBody .col-action {
  margin-top: 10px;
}

.newGridBody .col-action .btn-group {
  margin-left: 15px;
}

.newGridBody .col-action .btn-group ul li i {
  margin-right: 10px;
}

.newGridBody .pager .pageSels .chosen-container {
  width: 130px !important;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.newGridBody .pager .pager-new {
  margin-bottom: 10px;
}

.newGridBody .pager .pager-new .item {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 10px 0 0;
}

.newGridBody .pager .pager-new .item.pull-right {
  margin: 10px 0;
}

.newGridBody .pager .pager-new .item.pull-right .button-cogs {
  font-size: 13px;
  margin: 0 0 0 5px;
}

.newGridBody .pager .pager-new .item.pull-right .dropdown-menu {
  left: auto;
  right: 0;
  padding: 10px;
}

.newGridBody .pager .pager-new .item.pull-right .dropdown-menu ul {
  margin: 0;
  padding: 0;
}

.newGridBody .pager .pager-new .item.pull-right .dropdown-menu ul li {
  display: block;
  margin: 3px 0;
}

.newGridBody .pager .pager-new .item.pull-right .dropdown-menu ul li input {
  margin: -2px 5px 0 0;
}

.newGridBody .pager .pager-new .item.pull-right .dropdown-menu ul li label {
  color: #2e373e;
  cursor: pointer;
}

.newGridBody .pager .pager-new .item .pagination {
  margin: 0;
}

.newGridBody .pager .pager-new .item .pagination li a {
  border-radius: 0;
}

.newGridBody .pager .pager-new .item .pagination li span {
  border-radius: 0;
}

.newGridBody .pager .pager-new .item label {
  color: #8e8e8e;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
}

.newGridBody .pager .pager-new .item .chosen-container {
  width: 60px !important;
  margin-left: 10px;
}

table.tableDataNew {
  width: 100%;
}

table.tableDataNew thead tr th, table.tableDataNew thead tr td {
  background: #eee;
  position: relative;
  color: #555;
}

table.tableDataNew thead tr th a, table.tableDataNew thead tr td a {
  display: block;
  position: relative;
  color: #555;
  text-decoration: none;
}

table.tableDataNew thead tr th a span, table.tableDataNew thead tr td a span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

table.tableDataNew thead tr th a i, table.tableDataNew thead tr td a i {
  position: absolute;
  right: 0;
  top: 2px;
}

table.tableDataNew thead tr th a i.selected, table.tableDataNew thead tr td a i.selected {
  color: #f77c16;
}

table.tableDataNew tbody tr td {
  vertical-align: middle;
}

table.tableDataNew tbody tr td a {
  display: inline-block;
  vertical-align: middle;
  margin: 3px;
  font-size: 12px;
}

table.tableDataNew tbody tr td .shuffle {
  top: 50%;
  margin-top: -10px;
}

table.tableDataNew tbody tr td .shuffle a {
  font-size: 10px;
  margin: 0 1px;
}

table.tableDataNew tbody tr td .order_status {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  margin: 0 auto;
}

table.tableDataNew tbody tr td .payment_status {
  width: 60%;
  text-align: center;
  margin: auto;
}

table.tableDataNew tbody tr td .payment_status > span {
  display: block;
  height: 10px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #ddd;
  border: 1px solid #989898;
}

table.tableDataNew tbody tr td .payment_status > span.full {
  background: #4cae4c;
  border: 1px solid #428442;
}

table.tableDataNew tbody tr td .payment_status > span.more {
  background: #337ab7;
  border: 1px solid #285688;
}

table.tableDataNew tbody tr td .payment_status > span.red {
  background: #dd0000;
  border: 1px solid #a70000;
}

table.tableDataNew tbody tr td.extraRowArrow i {
  padding: 10px;
  display: block;
  cursor: pointer;
}

table.tableDataNew tbody tr td .icons .icon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  background: #2e373e;
  margin: 5px;
  padding: 5px;
  text-align: center;
}

table.tableDataNew tbody tr td .icons .icon.payu {
  background: #a6c307;
}

table.tableDataNew tbody tr td .icons .icon.paypal {
  background: #002f86;
}

table.tableDataNew tbody tr td .icons .icon.przelewy24 {
  background: #27303a;
}

table.tableDataNew tbody tr td .icons .icon.dpd {
  background: #d30032;
}

table.tableDataNew tbody tr td .icons .icon.ups {
  background: #5e3707;
}

table.tableDataNew tbody tr td .icons .icon.dhl {
  background: #fc0;
  color: #dd0000;
}

table.tableDataNew tbody tr td table {
  width: 100%;
  text-align: left;
}

table.tableDataNew tbody tr td table tr th {
  padding: 10px;
  background: #a3a3a3 !important;
  color: #fff;
  border-bottom: 1px solid #929292;
}

table.tableDataNew tbody tr td table tr td {
  padding: 10px;
  border-bottom: 1px solid #2c3339;
}

table.tableDataNew tbody tr:nth-child(even) td, table.tableDataNew tbody tr:nth-child(even) th {
  background: rgba(221, 221, 221, 0.2);
}

table.tableDataNew.tableInfo thead tr th, table.tableDataNew.tableInfo thead tr td {
  text-align: center;
  font-weight: normal;
}

table.tableDataNew.tableInfo tbody tr td {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

#system_modal .modal-body legend {
  display: none;
}

#system_modal .modal-body .chosen-container {
  width: 100% !important;
}

#system_modal .modal-body .tab-content {
  margin: 10px 0 0;
}

#system_modal .modal-body b.error {
  color: #dd0000;
  font-weight: normal;
}

#system_modal .modal-body label.error {
  color: #dd0000;
  font-weight: normal;
}

#system_modal .modal-body .required {
  color: #dd0000;
}

#login-form .row form {
  background: #fff;
  width: 350px !important;
  padding: 30px 20px;
  margin: 40px auto 0;
  border: 1px solid rgba(46, 55, 62, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#login-form .row form .company-logo {
  margin-bottom: 30px;
}

.wrapper .btn-return {
  margin-bottom: 10px;
}

.wrapper .btn-return i {
  margin-right: 5px;
}

.wrapper .col-xs-5 {
  margin-bottom: 50px;
}

.wrapper .col-xs-5 .panel {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.wrapper .col-xs-5 .panel .panel-heading {
  background: #eee;
}

.wrapper .col-xs-5 .panel .panel-heading a {
  display: block;
  font-size: 12px;
}

.wrapper .col-xs-5 .panel .panel-body p {
  margin: 0 0 0 2px;
}

#collapseTwo.set-rights table tr {
  border-bottom: 1px solid;
}

#collapseTwo.set-rights table tr:last-of-type, #collapseTwo.set-rights table tr.header {
  border-bottom: none;
}

#collapseTwo.set-rights table tr td {
  padding: 5px 3px;
  word-break: break-all;
  border-right: 1px solid;
}

#collapseTwo.set-rights table tr td:last-of-type {
  border-right: none;
}

#collapseTwo.set-rights table tr .chk input {
  width: auto !important;
  margin: 0 5px;
}

#collapseTwo.set-rights table tr .chk label {
  margin: 0 5px 0 0 !important;
}

#collapseTwo.set-rights table tr:not(.header):nth-child(odd) {
  background: #eee;
}

#collapseTwo.set-rights table tr.true {
  background: #dff0d8 !important;
  border-color: #d6e9c6 !important;
}

#collapseTwo.set-rights table tr.true td {
  border-color: #d6e9c6 !important;
}

#collapseTwo.set-rights table tr.true:hover {
  background: #cfe0c8 !important;
}

#collapseTwo.set-rights table tr.false {
  background: #f2dede !important;
  border-color: #ebccd1 !important;
}

#collapseTwo.set-rights table tr.false td {
  border-color: #ebccd1 !important;
}

#collapseTwo.set-rights table tr.false:hover {
  background: #e1cdcd !important;
}

#collapseTwo.set-rights table tr.default {
  background: #fcf8e3 !important;
  border-color: #faebcc !important;
}

#collapseTwo.set-rights table tr.default td {
  border-color: #faebcc !important;
}

#collapseTwo.set-rights table tr.default:hover {
  background: #e1ddc8 !important;
}

table tr td .chk2 label {
  padding: 0;
  margin: 0;
}

table tr td button {
  border-width: 0px;
}

table tr td a.btn-xs:hover {
  text-decoration: none;
}

table tr td.centerChk {
  text-align: center;
  vertical-align: middle;
}

table tr td.centerChk input[type="checkbox"] {
  margin: 0;
}

.miniModal {
  margin-top: 20px;
}

.miniModal select.sug_sel {
  width: 100%;
  left: -10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  margin: 30px 0 0 10px;
}

.noTabDataMessage {
  margin-top: 20px;
}

.newGridContainer {
  margin-top: 20px;
}
