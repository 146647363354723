.flex-layout {
  .sort-btns {
    //display: flex
    .sort-btn {
      display: inline-block;
      margin: 0 5px;
      padding: 2px 5px 0 5px;
      //background-color: #dddddd
      border-radius: 2px;
      a {
        color: black;
        text-decoration: none; }
      &:hover {
        background-color: #eeeeee;
        .fa, a, label {
          color: deepskyblue; } }
      .fa {
        font-size: 14px; }
      label {
        margin: 0 0 0 5px; }
      .end {
        color: #c0c0c0; }
      .not-allowed {
        cursor: not-allowed;
        color: #e6b8b8; } } } }

.sug {
  .head {
    //background-color: #c0c0c0
    //padding: 2px 0 2px 20px
    //margin: 0 !important
    //border-left: 2px solid #555
    strong {
      margin: 0 !important; } }
  .chk2 {
    //background-color: #f4f4f4
    //&:nth-child(even)
    ////background-color: #e7e7e7
    margin: 0;
    padding: 2px 0 2px 10px;
    font-size: 12px;
    input {
      margin: 0 5px 0 0; }
    label {
      margin: 0; } } }

.scrollWrap {
  .slEl {
    //background-color: #f4f4f4
    //&:nth-child(even)
 }    //  background-color: #e7e7e7
  &-pd {
    padding: 10px 0; }
  overflow: auto;
  &-brd {
    //background-color: #F8F8F8
    border: 2px solid #c0c0c0;
    border-right: none;
    border-left: none; } }
