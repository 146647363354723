$collumnsOnLarge: 6;
$collumnsOnMedium: 4;
$collumnsOnSmall: 2;
$collumnsOnmobile: 1;

$field-size-lg: 100%/$collumnsOnLarge;
$field-size-md: 100%/$collumnsOnMedium;
$field-size-sm: 100%/$collumnsOnSmall;
$field-size-mob: 100%/$collumnsOnmobile;

.fit {
  width: 100% !important; }

.tr-border {
  box-sizing: border-box;
  border: 3px solid transparent; }

%container {
  width: $field-size-lg;
  max-width: $field-size-lg;
  flex-grow: 1; }

.flex-grow {
  max-width: none !important;
  flex-grow: 1;
  width: 100%; }

.flex-form {
  input::-webkit-input-placeholder {
    font-size: 12px; }
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &-container {
    @extend %container;
    .flex-form-group-half {
      border: none;
      width: 100% !important; } }
  .chosen-container {
    width: 100% !important;
    input {
      font-size: 12px; } }
  &-group-half {
    @extend %container; }
  &-field {
    width: 100% !important; }
  &-btn-group {
    margin: 15px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //padding-left: 50%
    box-sizing: content-box; }
  &-btn {
    margin: 0 5px;
    .btn {
      margin: 0 !important; } }
  .search-field input {
    padding: 0 12px !important;
    color: #999999 !important; } }

@media only screen and (max-width: 1024px) {
  .flex-form {
    &-container {
      min-width: $field-size-md !important; }
    &-group-half {
      min-width: $field-size-md !important; }
    &-btn-group {
      padding-left: 0; }
    &-btn {
      width: 100%; } } }

@media only screen and (max-width: 720px) {
  .flex-form {
    &-container {
      min-width: $field-size-sm !important; }
    &-group-half {
      min-width: $field-size-sm !important; }
    &-btn-group {
      padding-left: 0; }
    &-btn {
      width: 100%; } } }

@media only screen and (max-width: 320px) {
  .flex-form {
    &-container {
      min-width: $field-size-mob !important; }
    &-group-half {
      min-width: $field-size-mob !important; }
    &-btn-group {
      flex-wrap: wrap;
      margin-top: 0; }
    &-btn {
      margin: 2px 0; } } }
