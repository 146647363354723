#menu-new {
  display: block;
  left: -100% !important;
  transition: .3s left ease-in-out;
  overflow: hidden !important;
  &.open {
    left: 0 !important;
    transition: .5s left ease-in-out;
    #mobile-menu-toggle {
      span:nth-child(1) {
        transform-origin: 50% 0%;
        transform: rotate(45deg) translate(8px, 7px); }
      span:nth-child(2) {
        border-color: #F77C16;
        transform-origin: 50% 50%;
        transform: scale(0, 1); }
      span:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -8px); } } } }

#mobile-menu-toggle {
  position: fixed;
  top: 0;
  left: 0;
  background: #2E373E;
  z-index: 1080;
  border-radius: 0 50% 50% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  //margin-top: 14px
  transition: .1s all ease-in;
  box-shadow: 1px 2px 2px rgba(2,2,2,.4);
  &:hover {
    box-sizing: border-box;
    border: 2px solid #F8F9FB; }
  span {
    border: 2px solid #F8F9FB;
    border-radius: 2px;
    width: 30px;
    margin: 3px 0 3px -3px;
    transition: .3s all ease-in-out; }
  span:nth-child(2) {
    border-color: #F77C16; } }

@media only screen and (min-width: 720px) {
  #mobile-menu-toggle {
    display: none; }
  #menu-new, #menu-new.open {
    left: 0 !important; } }

@media only screen and (max-width: 720px) {
  .new-body {
    display: flex;
    flex-direction: column;
    position: relative;
    #menu-new {
      width: 100%; }
    #content {
      width: 100%;
      margin: 0; } } }
