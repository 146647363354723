@import "libs/mixin";
@import "libs/variables";

strong.head {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  &+div {
    padding: 5px;
    border: 2px solid #eeeeee;
    border-top: none;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; } }

#content, .content {
  .bGridSearch {
    .col-xs-10 {
      width: calc(100% - 231px - (2*15px)); }
    .col-xs-2 {
      width: 231px;
      .form-group {
        margin: 10px 5px;
        .btn {
          margin: 0; } } }
    fieldset {
      background: transparent;
      text-align: left;
      .form-group {
        margin: 10px;
        &:first-of-type {
          margin: 0; }
        &:nth-child(6n+0) {
          margin: 0; }
        .input-group-addon {
          font-size: 12px; }
        .form-control {
          font-size: 12px; }
        .chosen-container-single {
          .chosen-single {
            font-size: 12px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; } }
        .chosen-choices {
          font-size: 12px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
        .tab-content {
          margin: 10px 0 0; }
        b {
          &.error {
            color: #dd0000;
            font-weight: normal; } }
        label {
          &.error {
            color: #dd0000;
            font-weight: normal; } }
        .required {
          color: #dd0000; } } } } }
#content.forceLanguageFix {
  margin-top: 50px; }
#header-new {
  .first-line {
    background: #fff;
    border-bottom: 1px solid rgba($menuColor, 0.1);
    .col-xs-7 {
      text-align: right;
      ul {
        right: 0;
        left: auto;
        a {
          color: $greyColor;
          font-size: 10px; } } } }
  .secount-line {
    padding: 5px 15px;
    background: #fff;
    border-bottom: 2px solid rgba($menuColor, 0.1);
    #localizer-new {
      margin-top: 10px;
      .localizer-item {
        color: $greyColor;
        margin: 10px;
        @include transform;
        position: relative;
        &:hover {
          color: $orangeColor; }
        &:last-child {
          color: $orangeColor; }
        &.no-arrow {
          margin: 0; }
        &:not(.no-arrow) {
          &:not(:last-child) {
            &:before {
              width: 10px;
              height: 10px;
              content: '';
              background: url("/gfx/refactor/arrow_down.png") no-repeat;
              position: absolute;
              right: -17px;
              top: 3px;
              -webkit-transform: rotate(270deg);
              -moz-transform: rotate(270deg);
              -ms-transform: rotate(270deg);
              -o-transform: rotate(270deg);
              transform: rotate(270deg); } } } } }
    #userDrop {
      background-color: #EAEBEC;
      display: inline-block;
      height: 100%;
      padding: 0 0 0 20px;
      border-radius: 20px;
      cursor: pointer;
      .user-image {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        img {
          max-width: 100%;
          min-height: 100%;
          border-radius: 150px; } }
      .user-name {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        p {
          color: $greyColor;
          @include transform;
          margin: 0; } }
      .dropdown-menu {
        margin-top: 5px;
        li {
          i {
            margin-right: 10px; }
          a {
            font-size: 12px; } } } } } }

.brd {
  border: 1px solid #aaa;
  clear: both;
  padding: 5px 10px;
  margin: 3px 0; }

.win_brd {
  display: none;
  position: relative;
  margin: 5px 0;
  border: 1px solid #aaa;
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 11px;
    height: 11px;
    background: url('/gfx/close.gif');
    z-index: 100;
    cursor: pointer; } }


#content, .content {
  hr {
    margin: 5px 0; }
  table {
    &.tableData {
      clear: both;
      color: #444;
      width: 100%;
      thead {
        img {
          &.srt_1 {
            position: relative;
            top: -4px;
            left: 4px;
            margin: 0; }
          &.srt_2 {
            position: relative;
            top: 4px;
            left: -7px;
            margin: 0; } }
        th, td {
          border: 1px solid #E3ECEE;
          //background: #D0DFE2
          padding: 5px;
          text-align: left;
          color: #333;
          white-space: nowrap; } }
      tbody {
        th, td {
          padding: 4px 5px;
          text-align: left;
          //background: #F2F2F0
          border: 1px solid #D0DFE2; }
        //tr.t
        ////th, td
        //  //background: #E7E7E3

        td img {
          margin: 0 2px;
          &.img {
            margin: 0 2px; } }
        tr {
          &:hover {
            th, td {
              background: #d2d2d0; } }
          &.t:hover {
            th, td {
              background: #d2d2d0; } }
          &.mx_up {
            th, td {
              border-top: 2px solid #444; } }
          &.mx_down {
            th, td {
              border-bottom: 2px solid #444; } } } } }
    &.mx_down {
      border-bottom: 2px solid #444; }
    &.tableData tbody tr {
      &.mx_in {
        th, td {
          background: #263538;
          color: #fff; } }
      &.mx_sel {
        th, td {
          background: #5C807C;
          color: #fff; } } } }
  .btn-no-rights {
    float: left;
    padding: 0 15px;
    border-bottom: 1px solid #A04300;
    border-right: 1px solid #A04300;
    border-left: 1px solid #ED6502;
    border-top: 1px solid #ED6502;
    background: #FFAC47 url('/gfx/nbtn_bg.gif') repeat-x 0 100%;
    color: #aaa;
    text-decoration: line-through;
    margin: 20px 5px;
    white-space: nowrap;
    vertical-align: middle;
    height: 19px;
    line-height: 19px; }
  .btn img, .btn-no-rights img {
    position: relative;
    top: -1px;
    left: -6px; }
  input.btn {
    margin: 0;
    width: auto; }
  .tableData {
    tr {
 }      //background: #F2F2F0
    //.btn-no-rights
    ////background: url('/gfx/btn_bg2.gif') repeat-x 0 100%
    ////border: 1px solid #ccc
    ////color: #aaa
    ////font-style: italic
    ////margin: 0 3px
    //.btn
    ////background: url('/gfx/btn_bg2.gif') repeat-x 0 100%
    ////border: 1px solid #ccc
    ////color: #666
    ////font-style: italic
    ////margin: 0 3px
    //img
    //  position: relative
    //  left: -6px
    //  top: -1px
    .btn-no-rights img {
      position: relative;
      left: -6px;
      top: -1px; }
    //.btn:focus:hover
 }    ////background: url('/gfx/btn_bg2.gif') repeat-x 0 100%
  .tabs {
    .btn, .btn-no-rights {
      //margin: 0 0 0 5px
      //float: none
      font-size: 9px;
      /*/border: none; */
      //background: url('/gfx/btn_bg2.gif') repeat-x 0 100%
      //border: 1px solid #ccc
 } }      //color: #666
  .ret {
    background: url('/gfx/btn_bg2.gif') repeat-x 0 100%;
    border: 1px solid #ccc;
    color: #666;
    img {
      position: relative;
      top: -1px;
      left: -6px; } }
  .tabs {
    //.btn:focus:hover, .btn-no-rights:focus:hover
    //  padding: 1px 4px
    //  /*/padding: 0 4px;
    //  margin: 0 0 0 5px
 }    //  float: none
  .form {
    float: left;
    width: 99%;
    margin: 0;
    padding: 2px;
    position: relative;
    top: 25px;
    border: 1px solid #ccc;
    background: #fff;
    table.list {
      clear: both;
      margin: 0 0 10px 60px;
      b.error {
        margin: 0; }
      th {
        padding: 7px 3px 2px 3px;
        border-bottom: 1px solid #ccc;
        text-align: left; }
      td {
        padding: 2px 6px; }
      label {
        width: auto; }
      input {
        margin: 0 3px 0 0; } }
    fieldset {
      margin: 0;
      padding: 10px;
      border: none;
      background: transparent url('/gfx/form_bg.gif') repeat-x;
      legend {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        position: absolute;
        color: #666;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        left: 10px;
        top: -25px;
        background: #fff; } } } }
#content, .content {
  form.form {
    select.multiple {
      height: 100px;
      min-width: 200px; }
    label {
      float: left;
      width: 29%;
      padding: 1px 10px 0 0;
      text-align: right;
      vertical-align: middle;
      &.ms_all {
        //float: none
        //width: auto
        //text-align: left
        //padding: 0 0 0 20px
        //margin: 0 0 0 5px
        //cursor: pointer
 } }        //background: url('/gfx/left_arrow.gif') left center no-repeat
    b.error {
      display: block;
      clear: both;
      color: #f00;
      margin: 0 0 0 210px;
      font-size: 10px; }
    div {
      &.chk {
        label {
          cursor: pointer; }
        input {
          cursor: pointer;
          border: none;
          width: auto; } }
      &.chk2 {
        label {
          width: auto;
          cursor: pointer;
          float: none; }
        input {
          cursor: pointer;
          border: none;
          width: auto; } } }
    td.chk {
      input {
        cursor: pointer;
        padding: 0;
        border: none;
        width: auto; }
      label {
        display: inline;
        cursor: pointer;
        padding: 0;
        margin: 0;
        width: auto;
        float: none; } } }
  div {
    &.brd {
      border: 1px solid #aaa;
      clear: both;
      padding: 5px 10px;
      margin: 3px 0;
      .btn {
        margin: 0; } }
    &.win_brd {
      display: none;
      background: #f8f8f8;
      position: relative;
      width: 100%;
      border: 1px solid #aaa;
      clear: both;
      padding: 5px 10px;
      margin: 10px 0; }
    &.msg_info {
      border: 1px solid #ccc;
      font-weight: bold;
      padding: 10px 15px;
      text-align: left;
      background: #fff;
      margin: 0 10px; } }
  p {
    &.msg {
      border: 1px solid #8DAE86;
      font-weight: bold;
      padding: 10px;
      margin: 5px 0;
      text-align: center;
      background: #DDF3CB; }
    &.msg_error {
      border: 1px solid #E08787;
      font-weight: bold;
      padding: 10px;
      text-align: center;
      background: #FEEAE4;
      margin: 5px 0; } }
  div.diver p {
    &.msg_error, &.msgr {
      margin: 20px 0 5px 0; } }
  span.required {
    color: #f00;
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 10px; }
  div#mask_window {
    .msg_error {
      position: absolute;
      padding: 5px 10px;
      top: -43px;
      left: 200px;
      right: 200px;
      z-index: 1005; }
    form.form {
      margin: 0;
      top: 0;
      fieldset legend {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        position: absolute;
        color: #666;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        left: 10px;
        top: -35px;
        background: #fff; } }
    span.required {
      right: 35px;
      top: -15px; } }
  label span.required, strong span.required {
    position: static; } }

#content, .content {
  form.form table.multi {
    margin: 0 0 0 200px; } }
#content, .content {
  form.noborders {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    position: static !important;
    background: transparent !important;
    fieldset {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      position: static !important;
      background: transparent !important; } } }
#content, .content {
  table.tableData.newGrid thead {
    span {
      padding-right: 30px; }
    img {
      &.srt_1 {
        position: absolute;
        top: 4px;
        right: 10px;
        margin: 0;
        left: auto !important; }
      &.srt_2 {
        position: absolute;
        top: 4px;
        right: 0;
        margin: 0;
        left: auto !important; } } } }
