.flexTabs {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  span {
    height: 30px;
    user-select: none;
    $W: 100%/7;
    width: $W;
    cursor: pointer;
    min-width: 100px;
    padding: 2px 5px;
    margin: 2px 2px 0 2px;
    flex-grow: 1;
    color: black;
    background-color: #EEEEEE;
    border: 1px solid transparent;
    &:hover {
      background-color: #ff9900; }
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &.a {
      transform: translate(0,1px);
      margin: 2px 2px 0 0;
      border: 1px solid #888;
      border-bottom: none;
      background-color: #FFFFFF;
      color: black;
      font-weight: bold;
      order: 1; } } }
