@import "../libs/mixin";
@import "../libs/variables";

table {
  tr {
    td {
      .chk2 label {
        padding: 0;
        margin: 0; }
      button {
        border-width: 0px; }
      a.btn-xs:hover {
        text-decoration: none; } }
    td.centerChk {
      text-align: center;
      vertical-align: middle;
      input[type="checkbox"] {
        margin: 0; } } } }
