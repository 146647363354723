@import "../libs/mixin";
@import "../libs/variables";

#system_modal {
  .modal-body {
    legend {
      display: none; }
    .chosen-container {
      width: 100% !important; }
    .tab-content {
      margin: 10px 0 0; }
    b {
      &.error {
        color: #dd0000;
        font-weight: normal; } }
    label {
      &.error {
        color: #dd0000;
        font-weight: normal; } }
    .required {
      color: #dd0000; } } }
