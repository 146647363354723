@import "../libs/mixin";
@import "../libs/variables";
table.tableDataNew {
  width: 100%;
  thead {
    tr {
      th, td {
        background: #eee;
        position: relative;
        color: #555;
        a {
          display: block;
          position: relative;
          color: #555;
          text-decoration: none;
          span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px; }
          i {
            position: absolute;
            right: 0;
            top: 2px;
            &.selected {
              color: $orangeColor; } } } } } }
  tbody {
    tr {
      td {
        vertical-align: middle;
        a {
          display: inline-block;
          vertical-align: middle;
          margin: 3px;
          font-size: 12px; }
        .shuffle {
          top: 50%;
          margin-top: -10px;
          a {
            font-size: 10px;
            margin: 0 1px; } }
        .order_status {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-weight: bold;
          color: #fff;
          margin: 0 auto; }
        .payment_status {
          width: 60%;
          text-align: center;
          margin: auto;
          > span {
            display: block;
            height: 10px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background-color: #ddd;
            border: 1px solid #989898;
            &.full {
              background: #4cae4c;
              border: 1px solid #428442; }
            &.more {
              background: #337ab7;
              border: 1px solid #285688; }
            &.red {
              background: #dd0000;
              border: 1px solid #a70000; } } }
        &.extraRowArrow {
          i {
            padding: 10px;
            display: block;
            cursor: pointer; } }
        .icons {
          .icon {
            @include border-radius(4px);
            width: 15px;
            height: 15px;
            line-height: 15px;
            color: #fff;
            background: #2e373e;
            margin: 5px;
            padding: 5px;
            text-align: center;
            &.payu {
              background: #a6c307; }
            &.paypal {
              background: #002f86; }
            &.przelewy24 {
              background: #27303a; }
            &.dpd {
              background: #d30032; }
            &.ups {
              background: #5e3707; }
            &.dhl {
              background: #fc0;
              color: #dd0000; } } }
        table {
          width: 100%;
          text-align: left;
          tr {
            th {
              padding: 10px;
              background: #a3a3a3 !important;
              color: #fff;
              border-bottom: 1px solid #929292; }
            td {
              padding: 10px;
              border-bottom: 1px solid $menuBorderColor; } } } }
      &:nth-child(even) {
        td, th {
          background: rgba(#dddddd, 0.2); } } } }
  &.tableInfo {
    thead {
      tr {
        th, td {
          text-align: center;
          font-weight: normal; } } }
    tbody {
      tr {
        td {
          font-weight: bold;
          font-size: 14px;
          text-align: center; } } } } }
